const timelineData = [
    {
        number: 0,
        date: "23rd May 2021",
        title: "Submission Deadline",
        content: ""
    },
    {
        number: 1,
        date: "26th May 2021",
        title: "Last Round",
        // content: "Last Round"
    },
    {
        number: 2,
        date: "27th May 2021",
        title: "Last Round",
        // content: "Last Round"
    },
]

export default timelineData